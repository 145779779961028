import { PauseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Typography,
} from 'antd';
import React, { useCallback, useState } from 'react';
import {
  reviewHolisticQuarterlyReportMutation,
  sendHolisticQuarterlyFeedbackMutation,
} from '../constants';

const { Title, Paragraph } = Typography;

const PutReportOnHoldBtn = ({ report, holisticProject, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewHolisticQuarterlyReport] = useMutation(
    reviewHolisticQuarterlyReportMutation,
  );
  const [sendHolisticQuarterlyFeedback] = useMutation(
    sendHolisticQuarterlyFeedbackMutation,
  );

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await reviewHolisticQuarterlyReport({
          variables: {
            reportId: report._id,
            status: 'HOLD',
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Progress report placed on hold',
        });
        if (values.sendFeedback) {
          await sendHolisticQuarterlyFeedback({
            variables: {
              reportId: report._id,
              feedback: values.feedback,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Feedback sent',
          });
        }
        setShowingModal(null);
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setLoading(false);
    },
    [
      reviewHolisticQuarterlyReport,
      sendHolisticQuarterlyFeedback,
      report,
      onSuccess,
    ],
  );

  return (
    <>
      <Button
        loading={loading}
        icon={<PauseOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Hold Proposal
      </Button>

      <Modal
        header={null}
        footer={null}
        visible={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 32 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 24 }}>
          Put Progress Report on Hold
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item>
            <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
              {`You're about to put the "${
                holisticProject?.projectName || 'No Project Name'
              }" progress report on hold.
                The submitter will not be notified unless you opt to send feedback below.`}
            </Paragraph>
          </Form.Item>
          <Form.Item name="sendFeedback" valuePropName="checked" noStyle>
            <div style={{ textAlign: 'center', marginBottom: 16 }}>
              <Checkbox>Send Feedback</Checkbox>
            </div>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.sendFeedback !== curValues.sendFeedback
            }
            noStyle
          >
            {({ getFieldValue }) =>
              getFieldValue('sendFeedback') && (
                <Form.Item
                  label="Feedback"
                  name="feedback"
                  rules={[
                    { required: true, message: 'Please add some feedback' },
                  ]}
                >
                  <Input.TextArea disable={loading} rows={4} />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item style={{ marginTop: 24 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Put progress report on Hold
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default PutReportOnHoldBtn;
