import { Space } from 'antd';
import React from 'react';
import DeletePublicFormButton from './DeletePulbicFormButton';

const ReviewButtons = ({ publicForm }) => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 100,
          right: 32,
          height: '100%',
        }}
      >
        <Space
          direction="vertical"
          style={{
            position: 'sticky',
            top: 32,
            zIndex: 1000,
          }}
        >
          <DeletePublicFormButton publicForm={publicForm} />
        </Space>
      </div>
    </>
  );
};

export default ReviewButtons;
