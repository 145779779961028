import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import DeletePublicFormModal from './DeletePublicFormModal';
import { useHistory } from 'react-router-dom';

function DeletePublicFormButton({ publicForm }) {
  const history = useHistory();
  const [deleting, setDeleting] = useState();
  const handleDelete = useCallback(() => {
    setDeleting({ publicForm });
  }, [publicForm]);
  const handleCancelDelete = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const handleFinishDelete = useCallback(() => {
    setDeleting(undefined);
    history.push('/public-forms');
  }, [history]);
  return (
    <>
      <Button icon={<DeleteOutlined />} onClick={() => handleDelete(true)}>
        Delete Form
      </Button>
      <DeletePublicFormModal
        onCancel={handleCancelDelete}
        onFinish={handleFinishDelete}
        deleting={deleting}
      />
    </>
  );
}

export default DeletePublicFormButton;
