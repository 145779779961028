import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { deletePublicFormMutation, PublicFormTypes } from './constants';

const { Paragraph } = Typography;

function DeletePublicFormModal({ deleting, onFinish, onCancel }) {
  const { publicForm } = deleting || {};
  const [loading, setLoading] = useState(false);
  const [deletePublicForm] = useMutation(deletePublicFormMutation);

  const formLabel =
    publicForm &&
    PublicFormTypes[publicForm.formType] &&
    PublicFormTypes[publicForm.formType].label;

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deletePublicForm({
        variables: {
          _id: publicForm._id,
        },
      });

      notification.success({
        message: 'Deleted',
        description: `${formLabel} deleted successfully`,
      });
      onFinish();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error has occurred.',
      });
    }
    setLoading(false);
  }, [deletePublicForm, publicForm, onFinish, formLabel]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  return (
    <YesNoModalJ10
      title="Delete Form"
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!publicForm}
      loading={loading}
    >
      <Paragraph>{`Are you sure you want to delete this ${formLabel}?`}</Paragraph>
    </YesNoModalJ10>
  );
}

export default DeletePublicFormModal;
