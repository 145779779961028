import { Space } from 'antd';
import React from 'react';

import { ReportApprovalStatuses } from '@aims/shared/reports/constants';

import useProfile from '../../../shared/use-profile';
import ApproveReportBtn from './ApproveReportBtn';
import MarkReportPendingBtn from './MarkReportPendingBtn';
import RejectReportBtn from './RejectReportBtn';
import PutReportOnHoldBtn from './PutReportOnHoldBtn';
import EditReportBtn from './EditReportBtn';

const ReviewButtons = ({ report, holisticProject }) => {
  let approveReportBtn = null;
  let rejectReportBtn = null;
  let markReportPendingBtn = null;
  let printReportBtn = null;
  let putOnHoldBtn = null;
  let editBtn = null;
  const profile = useProfile();

  switch (report.status) {
    case ReportApprovalStatuses.PENDING.key:
      approveReportBtn = (
        <ApproveReportBtn report={report} holisticProject={holisticProject} />
      );
      rejectReportBtn = (
        <RejectReportBtn report={report} holisticProject={holisticProject} />
      );
      putOnHoldBtn = (
        <PutReportOnHoldBtn report={report} holisticProject={holisticProject} />
      );
      editBtn = (
        <EditReportBtn report={report} holisticProject={holisticProject} />
      );
      break;
    case ReportApprovalStatuses.HOLD.key:
      approveReportBtn = (
        <ApproveReportBtn report={report} holisticProject={holisticProject} />
      );
      rejectReportBtn = (
        <RejectReportBtn report={report} holisticProject={holisticProject} />
      );
      editBtn = (
        <EditReportBtn report={report} holisticProject={holisticProject} />
      );
      /** TODO print report button
      printReportBtn = (
        <PrintReportBtn
          report={report}
          holisticProject={holisticProject}
          setError={setError}
          error={error}
          queryId={queryId}
          onSuccess={onSuccess}
        />
      )
      */
      break;
    case ReportApprovalStatuses.REJECTED.key:
    case ReportApprovalStatuses.APPROVED.key:
      if (profile.username === 'superadmin') {
        markReportPendingBtn = <MarkReportPendingBtn report={report} />;
      }
  }

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 100,
          right: 32,
          height: '100%',
        }}
      >
        <Space
          direction="vertical"
          style={{
            position: 'sticky',
            top: 32,
          }}
        >
          {approveReportBtn}
          {putOnHoldBtn}
          {rejectReportBtn}
          {markReportPendingBtn}
          {editBtn}
          {printReportBtn}
        </Space>
      </div>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ReviewButtons;
