import { updateReportAction } from '@aims/shared/redux-store/reports-store';
import { HolisticQuarterlyReportItems } from '@aims/shared/reports';
import {
  ReportApprovalStatuses,
  ReportTypes,
} from '@aims/shared/reports/constants';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import { updateHolisticProjectAction } from '../../redux-store/holistic-projects-store';
import useProfile from '../../shared/use-profile';
import useQueryParams from '../../shared/use-query-params';
import {
  extractHolisticProjectForAdmin,
  holisticProjectForAdminQuery,
} from '../holistic-projects/constants';
import DeleteHolisticQuarterlyReportModal from './DeleteHolisticQuarterlyReportModal';
import {
  createHolisticQuarterlyReportForAdminMutation,
  extractHolisticQuarterlyReportForAdmin,
  holisticQuarterlyReportForAdminQuery,
  submitHolisticQuarterlyReportForAdminMutation,
  unsubmitHolisticQuarterlyReportForAdminMutation,
  updateHolisticQuarterlyReportForAdminMutation,
} from './constants';
import { useHolisticQuarterlyReportBackLink } from './utils';

const { Title, Text } = Typography;

const queryId = 'editProgressReport';

function EditHolisticQuarterlyReport() {
  const { reportId } = useParams();
  const { holisticProjectId } = useQueryParams();

  const { loading: reportLoading } = useOneM1({
    variables: { _id: reportId },
    query: holisticQuarterlyReportForAdminQuery,
    extract: extractHolisticQuarterlyReportForAdmin,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    skip: !reportId,
    showError: false,
  });
  const report = useSingleSomethingA10('reports', reportId, queryId);

  const { loading: projectLoading, refetch } = useOneM1({
    variables: { _id: report?.holisticProjectId || holisticProjectId },
    query: holisticProjectForAdminQuery,
    extract: extractHolisticProjectForAdmin,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !(report?.holisticProjectId || holisticProjectId),
    showError: false,
  });
  const holisticProject = useSingleSomethingA10(
    'holisticProjects',
    report?.holisticProjectId || holisticProjectId,
    queryId,
  );

  const loading = reportLoading || projectLoading;
  const backLink = useHolisticQuarterlyReportBackLink();

  const history = useHistory();
  const [deleting, setDeleting] = useState();
  const onDelete = () => setDeleting({ report });
  const onCancelDelete = () => setDeleting(undefined);
  const onFinishDelete = () => {
    setDeleting(undefined);
    history.push('/holistic-projects');
  };

  const [saveLoading, setSaveLoading] = useState(false);
  const [updateReport] = useMutation(
    updateHolisticQuarterlyReportForAdminMutation,
  );
  const [createReport] = useMutation(
    createHolisticQuarterlyReportForAdminMutation,
  );
  const [submitReport] = useMutation(
    submitHolisticQuarterlyReportForAdminMutation,
  );
  const [unsubmitReport] = useMutation(
    unsubmitHolisticQuarterlyReportForAdminMutation,
  );
  const shouldSubmitReport = useRef();
  const finishSubForm = useRef();
  const profile = useProfile();

  const onSubmit = useCallback(
    async (__values) => {
      let msg;
      if (shouldSubmitReport.current === 'submit') {
        msg = 'Submitting Report ...';
      } else if (shouldSubmitReport.current === 'resubmit') {
        msg = 'Re-submitting Proposal ...';
      } else if (shouldSubmitReport.current === 'unsubmit') {
        msg = 'Unsubmitting Report ...';
      } else {
        msg = 'Saving Draft ...';
      }
      setSaveLoading(msg);
      const { notes, ..._values } = __values;
      try {
        const reportDetails = finishSubForm.current(_values);
        if (
          report &&
          ['unsubmit', 'resubmit'].includes(shouldSubmitReport.current)
        ) {
          await unsubmitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Success',
            description: 'Report unsubmitted.',
          });
        }
        if (report) {
          await updateReport({
            variables: {
              report: {
                _id: reportId,
                reportType: ReportTypes.HOLISTIC_PROGRESS.key,
                notes,
                holisticProjectId,
                ...reportDetails,
              },
            },
          });
        } else {
          await createReport({
            variables: {
              report: {
                _id: reportId,
                contactId: profile.contactId,
                reportType: ReportTypes.HOLISTIC_PROGRESS.key,
                notes,
                holisticProjectId,
                ...reportDetails,
              },
            },
          });
        }
        if (['submit', 'resubmit'].includes(shouldSubmitReport.current)) {
          await submitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report submitted successfully',
          });
          history.push(
            `/holistic-quarterly-reports/review/${reportId}?from=${location.pathname}`,
          );
        } else {
          notification.success({
            message: 'Success',
            description: 'Report saved.',
          });
          refetch();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your report.',
        });
      }
      setSaveLoading(false);
    },
    [
      reportId,
      createReport,
      history,
      report,
      submitReport,
      unsubmitReport,
      updateReport,
      profile,
      holisticProjectId,
      refetch,
    ],
  );

  const [form] = Form.useForm();
  const title = useMemo(() => {
    if (reportLoading) {
      return <Title>Holistic Quarterly Report</Title>;
    }
    if (report) {
      const status = ReportApprovalStatuses[report.status];
      return (
        <>
          <Title style={{ marginBottom: 16 }}>
            Edit Holistic Quarterly Report
          </Title>
          {status && (
            <div style={{ color: status.color, fontWeight: 600, fontSize: 18 }}>
              {status.label}
            </div>
          )}
        </>
      );
    }
    return (
      <Title style={{ marginBottom: 16 }}>
        Create Holistic Quarterly Report
      </Title>
    );
  }, [reportLoading, report]);

  return (
    <>
      <CSPage
        containerStyle={{ width: '100%', maxWidth: 'unset' }}
        title="Edit Quarterly Holistic Project Report"
      >
        <CSPageHeader
          titleComponent={<div />}
          backActions={[
            <Link to={backLink} key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Go back
              </Button>
            </Link>,
          ]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          id="EditReportForm"
          form={form}
          style={{
            maxWidth: 800,
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          {loading && (
            <>
              <div style={{ textAlign: 'center' }}>{title}</div>
              <Skeleton active title paragraph />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title paragraph />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title paragraph />
            </>
          )}
          {!loading && (
            <>
              <HolisticQuarterlyReportItems
                _id={reportId}
                report={report}
                loading={loading}
                form={form}
                finishSubForm={finishSubForm}
                holisticProject={holisticProject}
                fieldsToSet={{
                  holisticProjectId: report
                    ? report.holisticProjectId
                    : holisticProjectId,
                }}
              />
              <ShowErrorsFormItem />
              <div style={{ height: 16 }} />
              <Form.Item>
                <Space
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  {(!report ||
                    (report &&
                      report.status === ReportApprovalStatuses.DRAFT.key)) && (
                    <Button
                      key="draft"
                      type="dashed"
                      loading={saveLoading}
                      htmlType="button"
                      onClick={() => {
                        shouldSubmitReport.current = 'draft';
                        form.submit();
                      }}
                    >
                      Save Draft
                    </Button>
                  )}
                  {(!report ||
                    (report &&
                      report.status === ReportApprovalStatuses.DRAFT.key)) && (
                    <Button
                      key="submit"
                      type="primary"
                      loading={saveLoading}
                      htmlType="button"
                      onClick={() => {
                        shouldSubmitReport.current = 'submit';
                        form.submit();
                      }}
                    >
                      Submit Report
                    </Button>
                  )}
                  {report &&
                    report.status === ReportApprovalStatuses.PENDING.key && (
                      <Button
                        key="unsubmit"
                        type="dashed"
                        loading={saveLoading}
                        htmlType="button"
                        onClick={() => {
                          shouldSubmitReport.current = 'unsubmit';
                          form.submit();
                        }}
                      >
                        Unsubmit Report
                      </Button>
                    )}
                  {report && report.status === ReportApprovalStatuses.HOLD.key && (
                    <Button
                      key="re-submit"
                      type="primary"
                      loading={saveLoading}
                      htmlType="button"
                      onClick={() => {
                        shouldSubmitReport.current = 'resubmit';
                        form.submit();
                      }}
                    >
                      Re-Submit Report
                    </Button>
                  )}
                  {report &&
                    (report.status === ReportApprovalStatuses.HOLD.key ||
                      report.status === ReportApprovalStatuses.PENDING.key) && (
                      <Button
                        key="save"
                        type="primary"
                        loading={saveLoading}
                        htmlType="button"
                        onClick={() => {
                          shouldSubmitReport.current = 'save';
                          form.submit();
                        }}
                      >
                        Save Report
                      </Button>
                    )}
                </Space>
              </Form.Item>
            </>
          )}
        </Form>
        {report && (
          <>
            <Divider />
            <div style={{ textAlign: 'center' }}>
              <Button onClick={onDelete} icon={<DeleteOutlined />} danger ghost>
                Delete Report
              </Button>
            </div>
          </>
        )}
        <div style={{ minHeight: 300 }} />
        <Modal open={saveLoading} footer={null} closable={false} width={300}>
          <div style={{ textAlign: 'center', padding: 24 }}>
            <LoadingOutlined style={{ fontSize: 32, marginBottom: 16 }} />
            <Title level={4}>{saveLoading}</Title>
          </div>
        </Modal>
        <DeleteHolisticQuarterlyReportModal
          visible={deleting}
          onFinish={onFinishDelete}
          onCancel={onCancelDelete}
        />
      </CSPage>
    </>
  );
}

export default EditHolisticQuarterlyReport;
